
import { FarmerTypes } from "@/store/modules/farmers/farmers.types";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const farmer = namespace("Farmers");

@Component({
  components: {
    FarmerList: () => import("@/components/farmer/FarmerList.vue"),
  },
})
export default class FarmersView extends Vue {
  @farmer.Mutation(FarmerTypes.SET_ADD_FARMER_DIALOG)
  public setAddFarmer!: (addFarmer: boolean) => void;

  @farmer.Action(FarmerTypes.LOAD_FARMERS)
  public loadFarmers!: () => void;

  @farmer.State("loadingFarmer")
  public loadingFarmers!: boolean;

  openAddFarmerDialog(): void {
    this.setAddFarmer(true);
  }
}
